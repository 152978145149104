#about-main a {
  color: rgb(41, 122, 122);
}

#woodland-about {
  margin: 20px 5vw;
}

#woodland-about h2, #woodland-about h3 {
  text-align: left;
  margin: 2vw 0;
}

#tenants-about {
  /* background-image: linear-gradient(to bottom, rgba(240, 253, 240, 1), rgb(255, 255, 255, 0), rgb(255, 255, 255, 0), rgb(255, 255, 255, 0), rgba(240, 253, 240, 1));
  background-color: white; */
  margin: 100px 0;
}

#tenants-about h1, #woodland-about, .one-tenant, #about-bottom {
  text-align: center;
}

#tenants-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: top;
  margin: 20px 5vw;
  gap: 20px;
}

.one-tenant {
  width: 400px;
}

.one-tenant p {
  text-align: left;
  font-size: calc((1.5 - 1) * 1.2vw + .5rem);
}

.one-tenant img {
  width: 400px;
}

#about-bottom {
  width: 75vw;
  margin: 50px auto;
  padding: 0 50px;
}

#history {
  margin: 5vw;
}

#history-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 5%;
  align-items: flex-start;
}

#history-copy {
  width: 45%;
  min-width: 400px;
}

#history-copy p {
  font-size: calc((1.7 - 1) * 1.2vw + .6rem);
  margin: 1vw 0;
}

#woodland-1932 {
  width: 50%;
  max-width: 500px;
  margin: 1em 0;
}

@media screen and (max-width: 987px) {
  #history-copy {
    width: 90%;
    min-width: 0;
    max-width: 750px;
  }

  #woodland-1932 {
    width: 90%;
    max-width: 600px;
  }
}

@media screen and (max-width: 910px) {
  .one-tenant p, #woodland-about h2 {
    font-size: calc((2 - 1) * 1.2vw + .7rem);
  }

  .one-tenant {
    width: 90%;
  }
  
  .one-tenant img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  #woodland-1932 {
    width: 100%;
  }

  #woodland-about h2 {
    margin: 4vw auto;
  }

  #woodland-about {
    margin: 0px 5vw;
  }

  #tenants-about {
    margin: 10vw 0;
  }

  #about-bottom h4 {
    padding: 3vw;
  }

  #about-bottom {
    padding: 0;
    margin: 20px 5vw;
    width: 90vw;
  }

  #tenants-flex {
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 5vw;
  }

  #history {
    margin: 12vw auto 4vw auto;
  }

  #history-flex {
    flex-flow: column-reverse wrap;
    margin: 0 5vw;
  }

  #history-copy p {
    font-size: calc((1.7 - 1) * 1.2vw + .72rem);
    margin: 2vw;
  }

  #history-copy {
    margin: 2vw 0;
    width: 100%;
  }

  
}