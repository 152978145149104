#events-top {
    text-align: center;
    margin: 20px auto;
    padding: 0px 30px;
}

#events-list {
    border: 1px solid black;
    border-radius: 5px;
    margin: 0 5vw;
}

#cal-view-button,
#filler {
    font-size: 16px;
    width: 140px;
    height: 50px;
    color: white;
    border: 0;
    border-radius: 10px;
}

#future-events,
#past-events {
    padding: 10px;
    margin: 2vw auto;
    text-align: center;
}

.one-show {
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    /* border: 3px ridge rgb(2, 167, 158);
    border-radius: 30px; */
    /* background-color: white; */
    padding: 2vw;

}

.one-show:nth-of-type(odd) {
    background: rgba(194, 194, 194, .15);
}

.one-show:nth-of-type(even) {
    background: rgba(194, 194, 194, .3);
}

#show-date,
#show-categories {
    margin: 0;
    width: 10vw;
    flex-shrink: 0;
    text-align: center;
}

#show-categories {
    font-size: calc((1.7 - 1) * 1.2vw + .5rem);
}

#show-tags {
    width: 80%;
    min-width: 200px;
    line-break: normal;
}

#show-title {
    margin: 0 0 1vw 0;
    width: fit-content;
    max-width: 90%;
}

#show-title h4 {
    width: fit-content;
    text-align: left;
}

.event-band-link,
#show-title h4,
#band-links h4 {
    margin: 0 2vw;
    color: black;
    font-size: calc((1.7 - 1) * 1.2vw + .6rem);
    text-decoration: none;
}

.event-band-link {
    color: rgb(41, 122, 122);
    font-weight: 800;
}

.event-band-link:hover {
    color: rgb(2, 167, 158);
}

.one-show #show-tags #band-links {
    margin: 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
}

#past-events #events-list div #show-tags {
    padding: 0px;
}

#no-events p {
    font-size: calc((2 - 1) * 1.2vw + .5rem);
}

.no-events {
    width: 70vw;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    #events-top {
        flex-flow: column nowrap;
        margin: 0 5vw;
        padding: 0;
        /* gap: 10px; */
    }

    #events-title {
        font-size: calc((2 - 1) * 1.2vw + 1.5rem);
    }

    #cal-view-button {
        font-size: 14px;
        width: 120px;
        height: 30px;
    }

    #future-events h2 {
        font-size: calc((3 - 1) * 1.2vw + 1rem);
        margin: 4vw;
    }

    #filler {
        width: 0;
        height: 0;
        margin: 0;
    }

    #future-events,
    #past-events {
        padding: 0px;
        margin: 5vw auto;
    }

    #events-list {
        padding: 1vw;
        background-color: white;
    }

    .one-show {
        flex-flow: column nowrap;
        padding: 2vw;
    }

    #show-title {
        margin: 1vw auto;
    }

    #show-title h4 {
        text-align: center;
    }

    #show-date,
    #show-categories {
        width: auto;
        min-width: 100px;
        margin: 1vw auto;
    }

    .one-show #show-tags #band-links {
        flex-flow: column nowrap;
        margin: 2vw;
    }

    .event-band-link {
        display: block;
        font-size: 1rem;
        margin: 1.5vw auto;
        color: rgb(9, 132, 125);
    }

    #show-tags {
        width: 100%;
        padding: 0px;
    }

    #past-events #events-list div #show-tags {
        padding: 0px;
    }

    .no-events {
        width: 70vw;
        margin: 0 auto;
    }
}