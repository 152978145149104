.blue {
  /* background-color: rgb(2, 167, 159); */
  /* background-color: rgb(0, 63, 60); */
  /* background-color: rgb(0, 95, 90); */
  background-color: rgb(41, 122, 122);
  /* background-color: rgb(9, 81, 23); */
}

#logo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  /* transform: translate(-50%, 0); */
  width: calc(160px * 2);
  height: 160px;
  background: transparent;
}

#nav-container {
  position: sticky;
  top: 0;
  padding-bottom: 80px; 
}

#nav-flex {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 27.5px 20px;
  gap: 2vw;
}

#nav-links {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 20px;
}

#nav-links a, #nav-flex a {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

main h2 {
  text-align: center;
  margin: 20px;
}

#mailing-list {
  text-align: center;
  max-width: 550px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border: 12px solid transparent;
	border-image: 16 repeating-linear-gradient(-45deg, red 0, red 1em, transparent 0, transparent 2em,
	              #58a 0, #58a 3em, transparent 0, transparent 4em);
}

#mailing-list-copy h2 {
  margin: 0px auto;
}

#mailing-list-copy p {
  margin-bottom: 30px;
  font-size: 15px;
}

#mailing-list-address {
  height: 40px;
  width: 200px;
  margin: auto 20px;
  font-size: 16px;
}

#mailing-list-submit {
  height: 45px;
  font-size: 20px;
  /* background-color: rgb(2, 167, 158); */
  color: white;
  border: 0px;
  padding: 5px 30px;
  border-radius: 20px;
}

#mailing-list-errors div {
  margin: 20px;
  font-size: 12px;
}

#footer {
  text-align: left;
  padding: 2vw;
  margin: 0px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

#footer, #footer a {
  color: white;
}

#footer p, #footer h2 {
  margin: .5vw;
}

#IGFooter img {
  height: 50px;
  width: 50px;
}

#footer-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px 50px;
}

#IGGallery {
  margin: 5vw auto;
}

#IGGallery-all-pics {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2vw;
  gap: 2vw;
  max-width: 1200px;
  margin: 2vw auto;
}

.IGGallery-pic {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 1px solid black;
}

@media screen and (max-width: 1100px) {
  #logo {
    width: calc(2 * (160px + .1 * (100vw - 1100px)));
    height: calc(160px + .1 * (100vw - 1100px));
  }

  .IGGallery-pic {
    width: 27vw;
    height: 27vw;
    object-fit: cover;
    border: 1px solid black;
  }
}

@media screen and (max-width: 600px) {
  main h2 {
    text-align: center;
    margin: 3vw 5vw;
  }

  #nav-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 3vw;
    width: 94vw;
    gap: 20px;
  }

  #nav-container {
    padding-bottom: 60px;
  }

  #logo { 
    width: calc(2 * (100px + .1 * (100vw - 600px)));
    height: calc(100px + .1 * (100vw - 600px));
  }

  #nav-links {
    gap: 10px;
  }

  #to-home h1 {
    font-size: calc((1.7 - 1) * 1.2vw + 1rem);
    margin: 0;
  }

  #nav-flex a {
    font-size: 14px;
  }

  #IGGallery-all-pics {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    margin: 0 5vw;
    padding: 0;
  }

  #mailing-list-copy p {
    font-size: calc((1.7 - 1) * 1.2vw + .5rem);
    margin-bottom: 10px;
  }

  #mailing-list-submit, #mailing-list-address {
    height: 40px;
    font-size: 1rem;
    margin: 5px 10px;
  }

  #mailing-list-address {
    height: 30px;
    width: 60%;
    min-width: 150px;
    font-size: .8rem;
  }

  #mailing-list {
    padding: 3vw 2vw;
    margin: 5vw auto;
    width: 80vw;
  }

  #footer {
    padding: 3vw;
  }

  #footer-flex {
    display: block;
    margin-top: 20px;
  }

  #history h2 {
    font-size: 24px;
  }

  main {
    margin-top: -20px;
  }
}