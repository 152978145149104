@font-face {
  font-family: 'Space Grotesk';
  src: url(https://fonts.googleapis.com/css?family=Space+Grotesk);
  font-display: swap;
}

html, button, input, h1, h2, h3, h4, p, a {
  font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  font-weight: normal;
}

h1 {
  font-size: calc((2 - 1) * 1.2vw + 1rem);
}

h2 {
  font-size: calc((2 - 1) * 1.2vw + .85rem);
}

h3 {
  font-size: calc((2 - 1) * 1.2vw + .65rem);
}

h4 {
  font-size: calc((2 - 1) * 1.2vw + .5rem);
}

p {
  font-size: calc((1.5 - 1) * 1.2vw + .5rem);
}

body {
  margin: 0;
  /* background-color: rgb(240, 253, 240); */
  background-color: rgba(41, 122, 122, 0.1);
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: calc((1.5 - 1) * 1.2vw + 1rem);
  }
  
  h2 {
    font-size: calc((1 - 1) * 1.2vw + 1rem);
  }
  
  h3 {
    font-size: calc((2.5 - 1) * 1.2vw + .5rem);
  }
  
  h4 {
    font-size: calc((2 - 1) * 1.2vw + .5rem);
  }
  
  p {
    font-size: calc((1.5 - 1) * 1.2vw + .5rem);
  }
}