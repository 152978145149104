#music-main {
    margin: 1vw 0;
}

#all-of-the-bands {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 1vw 0;
}

#all-of-the-bands a {
    padding: 10px;
    width: 16vw;
    margin: 1vw auto;
    align-self: center;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 1px;
}

#all-of-the-bands a:hover{
    background-color: white;
    border-radius: 10px;
}

#music-top {
    text-align: center;
}

#music-top h1 {
    margin: auto;
}

#music-body {
    width: 100%;
    font-size: calc((2 - 1) * 1.2vw + .5rem);
    background-image: linear-gradient(to bottom, rgba(41, 122, 122, 0.1), rgb(255, 255, 255, 1), rgb(255, 255, 255, 1), rgb(255, 255, 255, 1), rgba(41, 122, 122, 0.1));
    margin: 0 auto;
    padding: 2vw;
    background-color: white;
}

#music-search {
    width: 90%;
    margin: 2vw auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 5vw;
}

#music-search input {
    width: 300px;
    height: 40px;
    padding: 10px 20px;
    font-size: 16px;
    border: 0;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 1px rgb(2, 167, 158);
}

.music-band h4 {
    margin: 5px;
    font-size: calc((1.7 - 1) * 1.2vw + .5rem);
}

.music-band {
    background-color: rgb(251, 255, 251);
}

#random-band {
    width: auto;
    /* background-color: rgb(2, 167, 158); */
    color: white;
    padding: 20px;
    border-radius: 20px;
    margin: 0;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    #music-search {
        flex-flow: column-reverse nowrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    #music-search input {
        width: 70%;
    }

    #random-band {
        width: fit-content;
        font-size: calc((2.5 - 1) * 1.2vw + .5rem);
    }

    #all-of-the-bands {
        grid-template-columns: 32% 32% 32%;
        gap: 20px 2%;
        margin: auto 3%;
    }

    #all-of-the-bands a {
        width: 25vw;
    }

    .music-band h4 {
        margin: 5px;
        font-size: calc((2.5 - 1) * 1.2vw + .5rem);
    }
}

@media screen and (max-width: 600px) {

    #music-top {
        margin: -2vw auto;
    }

    #all-of-the-bands {
        grid-template-columns: 100%;
        gap: 0px;
        margin: 10vw 3vw;
    }

    #all-of-the-bands a {
        width: 70vw;
        padding: 1vw;
    }

    #music-body {
        padding: 2vw 10vw;
        width: 80vw;
    }

    #music-search {
        margin: 4vw;
    }

    #music-search input {
        padding: 2vw 3vw;
        width: 68vw;
    }
}